<template>
  <div class="main">
    <HomeNav></HomeNav>
    <div class="c-box">
      <div class="person-box">
        <div class="c-top fs20" style="padding: 0 22px">消息中心</div>
        <div class="p-list">
          <!-- <template v-for="(item, index) in peopleList"> -->
          <div
            class="p-list-box"
            v-for="(item, index) in peopleList"
            :key="index"
            :class="item.userProfile.userID === sendUserId ? 'activeIndex' : ''"
            @click="choosePeopleList(item)"
            @contextmenu.prevent="rightMenu($event, item)"
          >
            <!-- v-if="
                item.lastMessage.payload.description &&
                item.lastMessage.payload.description != 'auth' &&
                item.lastMessage.payload.description &&
                item.lastMessage.payload.description != 'eva'
              " -->
            <span class="badge" v-if="item.unreadCount > 0">{{
              item.unreadCount
            }}</span>
            <img src="@/assets/img/avatarIM.png" alt="" />
            <div class="p-list-r">
              <div class="p-l-r-t">
                <!-- <span class="fs14">{{
                  item.conversationID.substring(3, item.conversationID.length)
                }}</span> -->
                <span class="fs14">{{ item.userProfile.nick }}</span>
                <span class="fs12 c999">{{
                  item.lastMessage ? getTime(item.lastMessage.lastTime) : ''
                }}</span>
              </div>
              <span
                class="p-l-r-b fs12"
                v-if="
                  item.lastMessage && item.lastMessage.type == 'TIMTextElem'
                "
                >{{ item.lastMessage.payload.text }}</span
              >
              <span
                class="p-l-r-b fs12"
                v-if="
                  item.lastMessage && item.lastMessage.type == 'TIMImageElem'
                "
                >[图片]</span
              >
              <span
                class="p-l-r-b fs12"
                v-if="
                  item.lastMessage && item.lastMessage.type == 'TIMFileElem'
                "
                >[文件]</span
              >
              <span
                class="p-l-r-b fs12"
                v-if="
                  item.lastMessage &&
                  item.lastMessage.type == 'TIMCustomElem' &&
                  item.lastMessage.payload.description == 'resume'
                "
                >[查看简历]</span
              >
              <span
                class="p-l-r-b fs12"
                v-if="
                  item.lastMessage &&
                  item.lastMessage.type == 'TIMCustomElem' &&
                  item.lastMessage.payload.description == 'creditEvaluation'
                "
                >[查看信用评价报告]</span
              >
            </div>
          </div>
          <!-- </template> -->
        </div>
      </div>
      <div class="message-box">
        <div class="c-top" style="padding: 0 34px" v-if="!sendUserId"></div>
        <div class="c-top" style="padding: 0 34px" v-else>
          {{ sendUserName }}
        </div>
        <div class="message-center-box" v-if="!sendUserId"></div>
        <div class="message-center-box" v-else>
          <div class="message-list-box">
            <template v-for="(item, index) in wordList">
              <!-- 对面 -->
              <div
                class="message-list-item m-l-i-l"
                :key="index"
                v-if="
                  item.flow == 'in' &&
                  !(
                    item.payload.description &&
                    item.payload.description == 'auth'
                  )
                "
              >
                <img class="avatars" src="@/assets/img/avatarIM.png" alt="" />
                <!-- 文本 -->
                <span class="messageBox" v-if="item.type == 'TIMTextElem'">{{
                  item.payload.text
                }}</span>
                <!-- 图片 -->
                <div v-if="item.type == 'TIMImageElem'">
                  <el-image
                    :src="item.payload.imageInfoArray[1].imageUrl"
                    :preview-src-list="[
                      item.payload.imageInfoArray[0].imageUrl,
                    ]"
                  >
                  </el-image>
                </div>
                <!-- 文件 -->
                <div
                  v-if="item.type == 'TIMFileElem'"
                  class="fileBox messageBox"
                  @click="fileDown(item)"
                >
                  <i class="el-icon-folder"></i>
                  <div class="fileInfo">
                    <span>{{ item.payload.fileName }}</span>
                    <span>{{ getSize(item.payload.fileSize) }}</span>
                  </div>
                </div>
              </div>
              <!-- 我 -->
              <div
                class="message-list-item m-l-i-r"
                :key="index"
                v-if="item.flow == 'out'"
              >
                <!-- 文本 -->
                <span class="messageBox" v-if="item.type == 'TIMTextElem'">{{
                  item.payload.text
                }}</span>
                <!-- 图片 -->
                <div v-if="item.type == 'TIMImageElem'">
                  <el-image
                    :src="item.payload.imageInfoArray[1].imageUrl"
                    :preview-src-list="[
                      item.payload.imageInfoArray[0].imageUrl,
                    ]"
                  >
                  </el-image>
                </div>
                <!-- 文件 -->
                <div
                  v-if="item.type == 'TIMFileElem'"
                  class="fileBox messageBox"
                  @click="fileDown(item)"
                >
                  <i class="el-icon-folder"></i>
                  <div class="fileInfo">
                    <span>{{ item.payload.fileName }}</span>
                    <span>{{ getSize(item.payload.fileSize) }}</span>
                  </div>
                </div>
                <!-- 查看简历 -->
                <div
                  v-if="
                    item.type == 'TIMCustomElem' &&
                    item.payload.description == 'resume'
                  "
                  class="resumeBox messageBox"
                  @click="getResume(item.payload.data)"
                >
                  <i class="el-icon-tickets"></i>
                  <span>查看简历</span>
                </div>
                <!-- 查看信用评价报告 -->
                <div
                  v-if="
                    item.type == 'TIMCustomElem' &&
                    item.payload.description == 'creditEvaluation'
                  "
                  class="resumeBox messageBox"
                  @click="seeResumeMessage(item.payload.data)"
                >
                  <i class="el-icon-document"></i>
                  <span>查看信用评价报告</span>
                </div>
                <img class="avatars" src="@/assets/img/avatarIM.png" alt="" />
              </div>
            </template>
          </div>
          <div class="message-text-box">
            <div class="toolbar">
              <el-upload
                class="upload-demo"
                ref="upload"
                action=""
                :on-change="changeImage"
                :auto-upload="false"
                :show-file-list="false"
                title="发送图片"
              >
                <i class="el-icon-picture-outline" style="font-size: 20px"></i>
              </el-upload>
              <el-upload
                class="upload-demo"
                ref="upload"
                action=""
                :on-change="changeFile"
                :auto-upload="false"
                :show-file-list="false"
                title="发送文件"
              >
                <i class="el-icon-folder" style="font-size: 20px"></i>
              </el-upload>
              <div class="resumeBtn" title="发送简历" @click="hasResume">
                <i class="el-icon-tickets" style="font-size: 20px"></i>
              </div>
              <div
                class="resumeBtn"
                title="发送信用评价报告"
                @click="sendCreditEvaluation"
              >
                <i class="el-icon-document" style="font-size: 20px"></i>
              </div>
            </div>
            <el-input
              type="textarea"
              placeholder="在这里输入内容. . ."
              v-model="textarea"
              :rows="2"
              resize="none"
              @focus="textareaFocus"
            >
            </el-input>
            <div style="text-align: right">
              <el-button
                type="primary"
                style="margin-right: 30px; margin-bottom: 20px; font-size: 16px"
                @click="send"
                >发送</el-button
              >
            </div>
          </div>
        </div>
      </div>
    </div>
    <SeeResume
      :seeFlag="seeFlag"
      :resume="resume"
      @change="changeFlag($event)"
      v-if="seeFlag"
    ></SeeResume>
    <SeeCreditEvaluation
      :creditEvaluationFlag="creditEvaluationFlag"
      :idCard="idCard"
      @closeEvaluation="closeEvaluation"
      v-if="creditEvaluationFlag"
    ></SeeCreditEvaluation>
    <div
      class="rightMouseMenu"
      :style="{ top: y + 'px', left: x + 'px' }"
      v-show="deleteFlag"
    >
      <div class="deleteChat" @click.stop="deleteOne">删除聊天</div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import HomeNav from '@/components/hometitle/hometitle.vue'
// // 从v2.11.2起，SDK 支持了 WebSocket，推荐接入；v2.10.2及以下版本，使用 HTTP
import TIM from 'tim-js-sdk'
import { caculateTimeago } from '@/utils/common.js'
import SeeResume from '@/components/seeResume/seeResume.vue'
import SeeCreditEvaluation from '@/components/seeCreditEvaluation/seeCreditEvaluation.vue'
import { detailResume } from '@/api/personalCenter/personalCenter'
import { getJobUserAuth } from '@/api/jobInformation/jobInformation'
export default {
  name: '',
  data() {
    return {
      textarea: '',
      messageList: [],
      // wordList: [], //单个对话消息
      // peopleList: [], //会话列表
      sendUserName: '', //对话人昵称
      sendUserId: '', //选择对话人id
      srcList: [], //查看图片列表
      seeFlag: false,
      resume: [],
      creditEvaluationFlag: false,
      idCard: null,
      x: 0,
      y: 0,
      deleteFlag: false,
    }
  },
  watch: {
    wordList(val1, val2) {
      this.scrollEvent()
    },
  },
  props: {},
  components: {
    HomeNav,
    SeeResume,
    SeeCreditEvaluation,
  },
  computed: {
    ...mapState(['peopleList', 'wordList', 'TIMUserId']),
  },
  created() {
    console.log(this.peopleList, 'peopleList')
    console.log(this.wordList, 'wordList')
    // console.log(this.$route);
    // let newId = this.$route.query.id
    // if(newId) {
    //   let peopleList = this.peopleList
    //   let obj = {
    //     userProfile: {
    //       userID: newId,
    //       nick: this.$route.query.nick
    //     }
    //   }
    //   this.peopleList.unshift(obj)
    //   this.$store.commit('set_peopleList', peopleList)
    // }
  },
  mounted() {
    window.addEventListener('mousedown', this.handleonmousedown)
    // console.log(this.$route)
    // let newId = this.$route.query.id
    // if (newId) {
    //   let peopleList = this.peopleList
    //   let obj = {
    //     userProfile: {
    //       userID: newId,
    //       nick: this.$route.query.nick,
    //     },
    //   }
    //   this.peopleList.unshift(obj)
    //   this.$store.commit('set_peopleList', peopleList)
    // }
  },
  methods: {
    // 选择上传图片
    changeImage(file) {
      console.log(file, '选择图片')
      this.sendImageMessage(file.raw)
    },
    // 选择上传文件
    changeFile(file) {
      console.log(file, '选择文件')
      this.sendFileMessage(file.raw)
    },
    // 下载文件
    fileDown(item) {
      window.location.href = item.payload.fileUrl
    },
    // 文件大小
    getSize(size) {
      if (size / 1024 / 1024 > 1) {
        return (size / 1024 / 1024).toFixed(1) + 'M'
      } else {
        return (size / 1024).toFixed(2) + 'K'
      }
    },
    // 消息时间
    getTime(time) {
      return caculateTimeago(time * 1000)
    },
    // 控制滚动条滚动到底部
    scrollEvent() {
      // 自动滚动到底部
      this.$nextTick(() => {
        let msg = document.getElementsByClassName('message-list-box')[0] // 获取对象
        console.log(msg)
        msg.scrollTop = msg.scrollHeight // 滚动高度
      })
    },
    // 文本框聚焦清空当前对话未读
    textareaFocus() {
      this.setRead()
    },
    // 点击单个会话列表
    // choosePeopleList(sendUserId) {
    choosePeopleList(item) {
      // this.textarea = ''
      this.sendUserName = item.userProfile.nick
      this.sendUserId = item.userProfile.userID
      this.$store.commit('set_TIMUserId', this.sendUserId)
      let option = {
        conversationID: 'C2C' + this.sendUserId,
        // nextReqMessageID: this.nextReqMessageID
      }
      this.getOneMessageList(option)
    },
    // 拉取单个会话列表
    getOneMessageList(option) {
      let that = this
      let promise = this.$tim.getMessageList(option)
      promise.then(function (imResponse) {
        console.log(imResponse, '拉取单个会话列表')
        // const messageList = imResponse.data.messageList // 消息列表。
        that.nextReqMessageID = imResponse.data.nextReqMessageID // 用于续拉，分页续拉时需传入该字段。
        const isCompleted = imResponse.data.isCompleted // 表示是否已经拉完所有消息。
        // that.wordList = imResponse.data.messageList
        that.messageList = imResponse.data.messageList
        that.$store.commit('set_wordList', that.messageList)
        // 设置已读
        that.setRead()
        // 设置滚动条到底部
        that.scrollEvent()
      })
    },
    // 发送消息
    send() {
      if (!this.textarea.trim()) {
        this.$message.warning('请输入内容')
        return
      }
      this.createMessage(this.textarea)
      this.textarea = ''
    },
    // 发送文本消息
    createMessage(text) {
      let that = this
      // 创建消息
      // 发送文本消息，Web 端与小程序端相同
      // 1. 创建消息实例，接口返回的实例可以上屏
      let message = this.$tim.createTextMessage({
        to: that.sendUserId,
        conversationType: TIM.TYPES.CONV_C2C,
        // 消息优先级，用于群聊（v2.4.2起支持）。如果某个群的消息超过了频率限制，后台会优先下发高优先级的消息，详细请参考：https://cloud.tencent.com/document/product/269/3663#.E6.B6.88.E6.81.AF.E4.BC.98.E5.85.88.E7.BA.A7.E4.B8.8E.E9.A2.91.E7.8E.87.E6.8E.A7.E5.88.B6)
        // 支持的枚举值：TIM.TYPES.MSG_PRIORITY_HIGH, TIM.TYPES.MSG_PRIORITY_NORMAL（默认）, TIM.TYPES.MSG_PRIORITY_LOW, TIM.TYPES.MSG_PRIORITY_LOWEST
        // priority: TIM.TYPES.MSG_PRIORITY_NORMAL,
        payload: {
          text: text,
        },
        // v2.20.0起支持C2C消息已读回执功能，如果您发消息需要已读回执，需购买旗舰版套餐，并且创建消息时将 needReadReceipt 设置为 true
        needReadReceipt: true,
        // 消息自定义数据（云端保存，会发送到对端，程序卸载重装后还能拉取到，v2.10.2起支持）
        // cloudCustomData: 'your cloud custom data'
      })
      // 发送消息
      let promise = this.$tim.sendMessage(message)
      promise
        .then(function (imResponse) {
          // 发送成功
          console.log('文本消息发送成功', imResponse)
          let message = imResponse.data.message
          that.messageList.push(message)
          that.$store.commit('set_wordList', that.messageList)
          // 滚动条到底部
          that.scrollEvent()
        })
        .catch(function (imError) {
          // 发送失败
          console.warn('sendMessage error:文本消息发送失败', imError)
        })
    },
    // 发送图片消息
    sendImageMessage(file) {
      let that = this
      // 1. 创建消息实例，接口返回的实例可以上屏
      let message = this.$tim.createImageMessage({
        to: that.sendUserId,
        conversationType: TIM.TYPES.CONV_C2C,
        payload: {
          file: file,
        },
        onProgress: function (event) {
          console.log('file uploading:', event)
        },
      })

      // 2. 发送消息
      let promise = this.$tim.sendMessage(message)
      promise
        .then(function (imResponse) {
          // 发送成功
          console.log('图片消息发送成功', imResponse)
          let message = imResponse.data.message
          that.messageList.push(message)
          that.$store.commit('set_wordList', that.messageList)
          // 滚动条到底部
          that.scrollEvent()
        })
        .catch(function (imError) {
          // 发送失败
          console.warn('sendMessage error:图片消息发送失败', imError)
          that.$message.error(imError.message)
        })
    },
    // 发送文件消息
    sendFileMessage(file) {
      let that = this
      let message = this.$tim.createFileMessage({
        to: that.sendUserId,
        conversationType: TIM.TYPES.CONV_C2C,
        payload: {
          file: file,
        },
        onProgress: function (event) {
          console.log('file uploading:', event)
        },
      })

      // 2. 发送消息
      let promise = this.$tim.sendMessage(message)
      promise
        .then(function (imResponse) {
          // 发送成功
          console.log('文件消息发送成功', imResponse)
          let message = imResponse.data.message
          that.messageList.push(message)
          that.$store.commit('set_wordList', that.messageList)
          // 滚动条到底部
          that.scrollEvent()
        })
        .catch(function (imError) {
          // 发送失败
          console.warn('sendMessage error:文件消息发送失败', imError)
          that.$message.error(imError.message)
        })
    },
    // 发送简历前查询是否填写过简历
    async hasResume() {
      let res = await detailResume({
        JobUserId: localStorage.getItem('userId'),
      })
      if (res.success && res.data) {
        this.sendResumeMessage()
      } else {
        this.$message.warning('暂无简历，请先前往个人中心填写简历')
      }
    },
    // 发送简历  自定义消息
    sendResumeMessage() {
      let that = this
      // 1. 创建消息实例，接口返回的实例可以上屏
      let message = this.$tim.createCustomMessage({
        to: that.sendUserId,
        conversationType: TIM.TYPES.CONV_C2C,
        // 消息优先级，用于群聊（v2.4.2起支持）。如果某个群的消息超过了频率限制，后台会优先下发高优先级的消息，详细请参考：https://cloud.tencent.com/document/product/269/3663#.E6.B6.88.E6.81.AF.E4.BC.98.E5.85.88.E7.BA.A7.E4.B8.8E.E9.A2.91.E7.8E.87.E6.8E.A7.E5.88.B6)
        // 支持的枚举值：TIM.TYPES.MSG_PRIORITY_HIGH, TIM.TYPES.MSG_PRIORITY_NORMAL（默认）, TIM.TYPES.MSG_PRIORITY_LOW, TIM.TYPES.MSG_PRIORITY_LOWEST
        // priority: TIM.TYPES.MSG_PRIORITY_HIGH,
        payload: {
          data: localStorage.getItem('userId'), // 自定义消息的数据字段
          description: 'resume', // 自定义消息的说明字段
          extension: '', // 自定义消息的扩展字段
        },
      })
      // 3. 发送消息
      let promise = this.$tim.sendMessage(message)
      promise
        .then(function (imResponse) {
          // 发送成功
          console.log('简历发送成功', imResponse)
          let message = imResponse.data.message
          that.messageList.push(message)
          that.$store.commit('set_wordList', that.messageList)
          // 滚动条到底部
          that.scrollEvent()
        })
        .catch(function (imError) {
          // 发送失败
          console.warn('sendMessage error:', imError)
          that.$message.error(imError.message)
        })
    },
    // 单个会话设置已读
    setRead() {
      // 将某会话下所有未读消息已读上报
      let promise = this.$tim.setMessageRead({
        conversationID: 'C2C' + this.sendUserId,
      })
      promise
        .then(function (imResponse) {
          // 已读上报成功，指定 ID 的会话的 unreadCount 属性值被置为0
          console.log(imResponse, '已读上报成功')
        })
        .catch(function (imError) {
          // 已读上报失败
          console.warn('setMessageRead error:已读上报失败', imError)
        })
    },
    // 获取简历
    getDetailResume(id) {
      detailResume({ JobUserId: id }).then(res => {
        this.resume = res.data
        this.seeFlag = true
        // this.avatar = res.data.avatar
        // this.tel = res.data.tel.replace(res.data.tel.substring(3, 7), '****')
        // // 获取简历之后再查询是否扣除过权益
        // if(this.resume.id && this.userId) {
        //   if(this.userId === this.resume.jobUserId){
        //     this.tel = this.resume.tel
        //   }
        //   this._oneEquityLogsNum()
        // }
      })
    },
    // 查看简历
    getResume(id) {
      this.getDetailResume(id)
      // this.seeFlag = true
    },
    changeFlag(value) {
      this.seeFlag = value
    },
    closeEvaluation(value) {
      this.creditEvaluationFlag = value
    },
    sendCreditEvaluation() {
      getJobUserAuth().then(res => {
        this.idCard = res.data.identity_code
        this.sendResumeMessage(this.idCard)
      })
    },
    // 发送信用评价报告  自定义消息
    sendResumeMessage(idCard) {
      let that = this
      // 1. 创建消息实例，接口返回的实例可以上屏
      let message = this.$tim.createCustomMessage({
        to: that.sendUserId,
        conversationType: TIM.TYPES.CONV_C2C,
        // 消息优先级，用于群聊（v2.4.2起支持）。如果某个群的消息超过了频率限制，后台会优先下发高优先级的消息，详细请参考：https://cloud.tencent.com/document/product/269/3663#.E6.B6.88.E6.81.AF.E4.BC.98.E5.85.88.E7.BA.A7.E4.B8.8E.E9.A2.91.E7.8E.87.E6.8E.A7.E5.88.B6)
        // 支持的枚举值：TIM.TYPES.MSG_PRIORITY_HIGH, TIM.TYPES.MSG_PRIORITY_NORMAL（默认）, TIM.TYPES.MSG_PRIORITY_LOW, TIM.TYPES.MSG_PRIORITY_LOWEST
        // priority: TIM.TYPES.MSG_PRIORITY_HIGH,
        payload: {
          data: localStorage.getItem('userId'), // 自定义消息的数据字段
          description: 'creditEvaluation', // 自定义消息的说明字段
          extension: idCard, // 自定义消息的扩展字段
        },
      })
      // 3. 发送消息
      let promise = this.$tim.sendMessage(message)
      promise
        .then(function (imResponse) {
          // 发送成功
          console.log('信用评价报告发送成功', imResponse)
          let message = imResponse.data.message
          that.messageList.push(message)
          that.$store.commit('set_wordList', that.messageList)
          // 滚动条到底部
          that.scrollEvent()
        })
        .catch(function (imError) {
          // 发送失败
          console.warn('sendMessage error:', imError)
          that.$message.error(imError.message)
        })
    },
    // 查看信用评价
    seeResumeMessage() {
      getJobUserAuth().then(res => {
        this.idCard = res.data.identity_code
        this.creditEvaluationFlag = true
      })
    },
    rightMenu(e, item) {
      this.x = e.clientX
      this.y = e.clientY
      this.deleteOneData = item
      this.deleteFlag = true
    },
    // 删除单个会话
    deleteOne() {
      let that = this
      let promise = this.$tim.deleteConversation(
        that.deleteOneData.conversationID
      )
      promise
        .then(function (imResponse) {
          // 删除会话成功
          const { conversationID } = imResponse.data // 被删除的会话 ID
        })
        .catch(function (imError) {
          console.warn('deleteConversation error:', imError) // 删除会话失败的相关信息
        })
      that.deleteFlag = false
      that.sendUserName = ''
      that.sendUserId = ''
    },
    // 取消右键菜单
    handleonmousedown(e) {
      if (e.target.className !== 'deleteChat') {
        this.deleteFlag = false
      }
    },
  },
  destroyed() {
    this.$store.commit('set_TIMUserId', '')
  },
  filters: {},
}
</script>

<style scoped lang="scss">
.fs20 {
  font-size: 20px;
}
.fs14 {
  font-size: 14px;
}
.fs12 {
  font-size: 12px;
}
.c999 {
  color: #999;
}
.activeIndex {
  background-color: rgba(0, 110, 255, 0.1);
}
.c-box {
  width: 1200px;
  // height: 700px;
  height: 550px;
  margin: 120px auto 0;
  background-color: #fff;
  display: flex;
  border-radius: 12px;
  box-shadow: #ccc 10px 10px 10px;
  .person-box {
    width: 300px;
    border: 1px solid #eee;
    display: flex;
    flex-direction: column;
  }
  .message-box {
    flex: 1;
    border: 1px solid #eee;
    display: flex;
    flex-direction: column;
  }
  .c-top {
    height: 60px;
    line-height: 60px;
    border-bottom: 1px solid #eee;
  }
}
.p-list {
  flex: 1;
  overflow-y: auto;
  .p-list-box:hover {
    background-color: rgba(0, 110, 255, 0.1);
    cursor: pointer;
  }
  .p-list-box {
    display: flex;
    padding: 22px 10px;
    position: relative;
    .badge {
      width: 20px;
      height: 20px;
      background-color: red;
      border-radius: 50%;
      position: absolute;
      top: 16px;
      left: 44px;
      color: #fff;
      text-align: center;
      line-height: 20px;
      font-size: 12px;
    }
    img {
      width: 48px;
      height: 48px;
      border-radius: 50%;
      object-fit: cover;
    }
    .p-list-r {
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      margin-left: 10px;
      .p-l-r-t {
        display: flex;
        span:nth-last-child(1) {
          margin-left: auto;
          margin-right: 0;
        }
      }
      .p-l-r-b {
        -webkit-line-clamp: 1;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        overflow: hidden;
      }
    }
  }
}

.message-center-box {
  // flex: 1;
  height: 490px;
  display: flex;
  flex-direction: column;
  .message-list-box {
    // flex: 1;
    height: 340px;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    background: #f4f4f4;
    // height: calc();
    .message-list-item {
      display: flex;
      padding: 20px;
      .fileBox {
        display: flex;
        align-items: center;
        cursor: pointer;
        i {
          font-size: 26px;
          color: #666;
          margin-right: 10px;
        }
        .fileInfo {
          display: flex;
          flex-direction: column;
        }
      }
    }
    .m-l-i-l {
      margin-left: 0;
      margin-right: auto;
      .avatars {
        width: 44px;
        height: 44px;
        object-fit: cover;
        border-radius: 50%;
        margin-right: 20px;
      }
      .messageBox {
        // display: inline-block;
        background-color: #fbfbfb;
        padding: 12px;
        border-radius: 0px 10px 10px 10px;
        margin-right: 150px;
      }
      .resumeBox {
        color: #666;
        cursor: pointer;
        span {
          margin-left: 6px;
        }
      }
    }
    .m-l-i-r {
      margin-left: auto;
      margin-right: 0;
      .avatars {
        width: 44px;
        height: 44px;
        object-fit: cover;
        border-radius: 50%;
        margin-left: 20px;
      }
      .messageBox {
        // display: inline-block;
        background-color: #dceafd;
        padding: 12px;
        border-radius: 10px 0px 10px 10px;
        margin-left: 150px;
      }
      .resumeBox {
        color: #666;
        cursor: pointer;
        span {
          margin-left: 6px;
        }
      }
    }
  }
  .message-text-box {
    height: 150px;
    border-top: 1px solid #eee;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .toolbar {
      display: flex;
      padding: 10px 14px 0 14px;
      .upload-demo {
        margin-right: 10px;
      }
      .resumeBtn {
        cursor: pointer;
        margin-right: 10px;
      }
    }
  }
}

.p-list::-webkit-scrollbar {
  display: none;
}
::v-deep .el-textarea__inner::-webkit-scrollbar {
  display: none;
}
::v-deep .el-textarea__inner {
  border: none !important;
  font-size: 16px;
}

.message-list-box::-webkit-scrollbar {
  display: none;
}

.rightMouseMenu {
  box-shadow: 0px 0px 2px 1px #ccc;
  position: fixed;
  background-color: #fff;
  // padding: 10px 20px;
  .deleteChat {
    padding: 10px 20px;
  }
  .deleteChat:hover {
    cursor: pointer;
    background-color: #eee;
  }
}
</style>
